@font-face {
  font-family: 'Trasandina Bold';
  src: url('./assets/fonts/TrasandinaBold.eot');
  src: 
    url('./assets/fonts/TrasandinaBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/TrasandinaBold.woff') format('woff'),
    url('./assets/fonts/TrasandinaBold.ttf') format('truetype'),
    url('./assets/fonts/TrasandinaBold.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Trasandina Regular';
  src: url('./assets/fonts/TrasandinaRegular.eot');
  src: 
    url('./assets/fonts/TrasandinaRegular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/TrasandinaRegular.woff') format('woff'),
    url('./assets/fonts/TrasandinaRegular.ttf') format('truetype'),
    url('./assets/fonts/TrasandinaRegular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Trasandina Medium';
  src: url('./assets/fonts/TrasandinaMedium.eot');
  src: 
    url('./assets/fonts/TrasandinaMedium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/TrasandinaMedium.woff') format('woff'),
    url('./assets/fonts/TrasandinaMedium.ttf') format('truetype'),
    url('./assets/fonts/TrasandinaMedium.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Trasandina Book';
  src: url('./assets/fonts/TrasandinaBook.eot');
  src:
    url('./assets/fonts/TrasandinaBook.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/TrasandinaBook.woff') format('woff'),
    url('./assets/fonts/TrasandinaBook.ttf') format('truetype'),
    url('./assets/fonts/TrasandinaBook.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Trasandina Book', sans-serif !important;
}

h1, h2, h3, h4, h5, h6,.nav-link, b, strong {
  font-family: 'Trasandina Bold', sans-serif !important;
}

.bg-girls {
  background-color: #FF69B4 !important;
}

.bg-skv-yellow {
  background-color: #FFEC00 !important;
}

/* BTN SKV Yellow*/

.btn-skv-yellow {
  color: rgba(0, 0, 0, 1);
  background-color: #FFEC00;
  border-color: #FFEC00;
}

.btn-skv-yellow:hover {
  color: rgba(0, 0, 0, 0.7);
  background-color: rgba(255, 236, 0, 0.7);
  border-color: #FFEC00;
}

.btn-skv-yellow:focus, .btn-skv-yellow.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.7);
}

.btn-skv-yellow.disabled, .btn-skv-yellow:disabled {
  color: #FFEC00;
  background-color: transparent;
}

.btn-skv-yellow:not(:disabled):not(.disabled):active, .btn-skv-yellow:not(:disabled):not(.disabled).active,
.show > .btn-skv-yellow.dropdown-toggle {
  color: rgba(0, 0, 0, 1);
  background-color: #FFEC00;
  border-color: #FFEC00;
}

.btn-skv-yellow:not(:disabled):not(.disabled):active:focus, .btn-skv-yellow:not(:disabled):not(.disabled).active:focus,
.show > .btn-skv-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.7);
}

/* Custom card */

.card {
  flex-direction: row;
}

.card .img-holder {
  width: 100%
}

@media screen and (max-width: 576px) {
  .card {
    flex-direction: column;
    align-items:center;
  }
  .card .img-holder {
    padding-top: 5%;
    width: 50%;
  }
}

@media screen and (max-width: 448px) {
  .card .img-holder {
    padding-top: 0;
    width: 100%;
  }
}

/* Header */
@-webkit-keyframes fadeIt {
  0%   { background-color: rgba(0,0,0,0.8); }
  100% { background-color: rgba(0,0,0,0.33); }
}

@-moz-keyframes fadeIt {
  0%   { background-color: rgba(0,0,0,0.8); }
  100% { background-color: rgba(0,0,0,0.33); }
}

@-o-keyframes fadeIt {
  0%   { background-color: rgba(0,0,0,0.8); }
  100% { background-color: rgba(0,0,0,0.33); }
}
@keyframes fadeIt {
  0%   { background-color: rgba(0,0,0,0.8); }
  100% { background-color: rgba(0,0,0,0.33); }
}

.header  {
  position: relative; 
  background: url('./assets/images/nabory_title_2.jpg');
  background-position: center center;
  background-size: cover;
  height: 300px;
}

.header::before {    
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  -moz-animation: fadeIt 2s ease-out; 
  -webkit-animation: fadeIt 2s ease-out; 
  -o-animation: fadeIt 2s ease-out; 
  animation: fadeIt 2s ease-out;

  background-color: rgba(0,0,0,0.33);
}

.header .container-fluid {
  height: 100%;
}

.header .container-fluid .row {
  height: 100%;
}

.fans {
  display: table;
  height: 100%;
  table-layout: fixed;
}

.fans h1 {
  color: #fce217;
  text-align: center;
  font-size: 2.5rem;
  font-family: 'Trasandina Medium';
  padding-left: 40px;
  padding-right: 40px;
  text-shadow: 1px 2px 20px #000000;
  display: table-cell;
  vertical-align: middle;
}

.sticky_logo {
  position: sticky;  
  height: 50px;

  top:0; left:0; right:0;
  margin:auto;

  background-color: rgba(255,255,255,1);

  z-index: 100;
  box-shadow: 1px 2px 20px #000000;
}

.sticky_logo img {
  height: 50px
}

.hr-theme-slash-2 {
  display: flex;
}

  
.hr-theme-slash-2 .hr-line {
  width: 100%;
  position: relative;
  margin: 15px;
  margin-bottom: 46px;
  border-bottom: 1px solid #ddd;
}
.hr-theme-slash-2  .hr-icon {
  position: relative;
  top: -10px;
  color: #ddd;
}

.MuiTypography-body2
{
    font-family: 'Trasandina Bold', sans-serif !important;
}

.react-date-picker__wrapper
{
  border:none;
}

.form-group.required .form-label:after {
  content:"*";
  color:red;
}

.videoWrapper {
	position: relative;
	padding-bottom: 54.0%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	overflow: hidden;
  margin-bottom: 25px;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}